<template>
  <v-container>
    <h1>{{ verb }} User</h1>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.name"
              :counter="30"
              :disabled="loading"
              :rules="rules.name"
              label="Name"
              required
            />
            <v-text-field
              v-model="form.email"
              :disabled="loading"
              :rules="rules.email"
              label="E-mail"
              type="email"
              required
            />
            <v-text-field
              v-model="form.password"
              :disabled="loading"
              :rules="rules.password"
              label="Password"
              type="password"
              required
            />
            <v-text-field
              v-model="form.password_confirmation"
              :disabled="loading"
              :rules="passwordConfirmationRule"
              label="Password Confirmation"
              type="password"
              required
            />
            <v-text-field
              v-model="form.phone_number"
              :disabled="loading"
              :rules="rules.phone_number"
              label="Phone Number"
              prefix="+62"
              required
            />
            <v-select
              v-model="form.role"
              :disabled="loading"
              :items="roleItems"
              item-text="label"
              item-value="value"
              label="Role"
            />
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import User from "@/services/user";

export default {
  name: "User.Form",
  data() {
    return {
      valid: false,
      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone_number: "",
        role: ""
      },
      rules: {
        name: [
          v => !!v || "Name is required",
          v =>
            (v.length >= 5 && v.length <= 50) ||
            "Name must be greater than 5 and less then 30",
          v =>
            new RegExp(/^[\w\-\s]+$/i).test(v) ||
            "Name must only contain alphanumeric characters"
        ],
        email: [
          v => !!v || "E-mail is required",
          v =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail must be valid"
        ],
        password: [
          v => !!this.dataId || !!v || "Password is required",
          v =>
            !!this.dataId ||
            (v.length >= 6 && v.length <= 50) ||
            "Password must be greater than 6 and less then 30"
        ],
        password_confirmation: [
          v => !!this.dataId || !!v || "Password is required"
        ],
        phone_number: [
          v => !!v || "Phone number is required",
          v =>
            /^8\d{8,11}$/.test(v) ||
            "Phone number does not match with the format"
        ]
      },
      roleItems: [
        {
          label: "Admin",
          value: "admin"
        },
        {
          label: "Student",
          value: "student"
        }
      ],
      loading: false
    };
  },
  computed: {
    verb() {
      return this.$route.params.id ? "Update" : "Create";
    },
    pastVerb() {
      return `${this.verb}d`;
    },
    dataId() {
      return this.$route.params.id;
    },
    passwordConfirmationRule() {
      return [
        ...this.rules.password_confirmation,
        () =>
          !!this.dataId ||
          this.form.password === this.form.password_confirmation ||
          "Password confirmation does not match"
      ];
    }
  },
  async created() {
    this.setNavbarTitle("Manage User");
    if (this.dataId) {
      const user = await User.find(this.dataId);
      const { name, email, phoneNumber, role } = user.data.data;
      this.form = { name, email, phone_number: phoneNumber.slice(3), role };
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async submit() {
      this.loading = true;
      try {
        if (this.dataId) {
          await User.update(this.dataId, {
            ...this.form,
            phone_number: `+62${this.form.phone_number}`
          });
        } else {
          await User.create({
            ...this.form,
            phone_number: `+62${this.form.phone_number}`
          });
        }

        this.$snackbar.showMessage({
          content: `Data ${this.pastVerb.toLowerCase()}`,
          variant: "success"
        });
        this.$router.push({ name: "User.Index" });
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>
